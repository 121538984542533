<template>
  <div>
    <div class="d-flex justify-center align-center" v-if="!isCameraOpen">
      <TipsPhoto></TipsPhoto>
    </div>
    <div
      v-if="!isCameraOpen && parseInt(carouselIndex) === 2"
      class="justify-center"
    >
      <div class="mx-auto">
        <div class="d-flex flex-row align-center">
          <svg
            :style="{
              position: 'fixed' /* Fixe le bouton */,
              bottom: '20px',
              left: '25px',
            }"
            @click="previousSlide"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="47"
              height="47"
              rx="23.5"
              fill="white"
              stroke="#9E9E9E"
            />
            <path
              d="M25 20L21 24L25 28"
              :stroke="WEBAPPPRO_BUTTON_COLOR_1"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <v-btn
            class="parti"
            @click="toggleCamera"
            :color="WEBAPPPRO_BUTTON_COLOR_1"
            :style="{
              color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
              borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
              textTransform: 'none',
              marginBottom: '10px',
              padding: '25px 100px',
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              width: '260px',
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'fixed' /* Fixe le bouton */,
              position: 'fixed',
              bottom: '8px',
              right: '25px',
            }"
          >
            C'est parti
          </v-btn>
          <!-- <v-btn
            :style="{
              background: 'transparent',
              border: 'none',
              boxShadow: 'none',
              color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
              textTransform: 'none',
              padding: '0',
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
              fontWeight: 'normal',
              cursor: 'pointer',
              position: 'fixed' /* Fixe le bouton */,
              bottom: '10px',
            }"
            elevation="0"
            text
            @click="reviewSlide"
          >
            Revoir les conseils
          </v-btn> -->
        </div>
      </div>
    </div>

    <div
      v-show="isCameraOpen && isLoading"
      class="text-center align-middle"
      style="height: 300px"
    >
      <div
        class="text-caption"
        align="center"
        :style="{ color: WEBAPPPRO_BUTTON_COLOR_1 }"
      >
        Veuillez accepter les autorisations d'utiliser votre caméra.
      </div>
      <br />
      <v-progress-circular
        :color="WEBAPPPRO_BUTTON_COLOR_1"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
      <br />
      <span :style="{ color: WEBAPPPRO_BUTTON_COLOR_1 }"
        >Veuillez patienter</span
      >
    </div>

    <div
      v-if="isCameraOpen"
      v-show="!isLoading"
      class="camera-box d-flex justify-center overflow-hidden"
      :class="{ flash: isShotPhoto }"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video
        v-show="!isPhotoTaken"
        :width="videoWidth"
        :height="videoHeight"
        v-bind:class="{ 'camera-live-invert': frontCamera }"
        ref="camera"
        autoplay
        playsinline
      ></video>

      <v-img
        v-show="downloadLink"
        :src="downloadLink"
        ref="canvas"
        :style="{ overflow: 'hidden' }"
        :width="videoWidth * imageDetouredCoefDimension"
        :height="videoHeight * imageDetouredCoefDimension"
        class="my-10"
        contain
      >
        <!-- Ajouter le v-progress-linear pour indiquer le chargement de la photo -->
        <!---   <v-progress-linear
          v-if="!isLoadingPhoto"
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          indeterminate
        ></v-progress-linear>-->
        <v-sheet
          v-if="processingInProgress"
          :height="maskHeight"
          class="
            mask
            transparent
            d-flex
            flex-column
            justify-center
            align-center
          "
          :style="{ top: masktop }"
        >
          <v-progress-circular
            :color="WEBAPPPRO_BUTTON_COLOR_1"
            indeterminate
          ></v-progress-circular>
          <p
            class="text-clignote mt-20"
            :style="{ color: WEBAPPPRO_BUTTON_COLOR_1 }"
          >
            Traitement en cours...
          </p>
        </v-sheet></v-img
      >

      <canvas
        id="realPhotoTaken"
        ref="realCanvas"
        :style="{ display: 'contents' }"
      ></canvas>
      <div></div>
    </div>

    <!-- Button switch camera -->
    <div class="adjust">
      <div
        v-if="isCameraOpen"
        v-show="!isLoading"
        class="camera-change d-flex flex-row justify-center align-center"
      >
        <!-- <svg
          class="mr-2"
          @click="nextCamera"
          :style="{ borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND }"
          v-show="!isPhotoTaken"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="52"
            height="52"
            rx="8"
            :fill="WEBAPPPRO_BUTTON_COLOR_1"
            fill-opacity="0.4"
          />
          <path
            d="M19.2759 23.7977C18.8138 23.3238 18.0535 23.3127 17.5792 23.7758L16.0805 25.2356C16.0488 25.2832 15.9684 25.356 15.8762 25.435C16.1598 20.872 19.5456 17.1275 24.1267 16.4294C27.6648 15.8897 31.2075 17.3293 33.3723 20.1861C33.6096 20.4983 33.9976 20.6559 34.3859 20.5964C34.5599 20.57 34.7269 20.4993 34.8686 20.3921C35.1807 20.1548 35.3383 19.7672 35.2799 19.3792C35.2531 19.2042 35.1821 19.0375 35.0749 18.8959C32.4373 15.4144 28.1189 13.6603 23.8051 14.3179C18.1739 15.1767 14.0135 19.8082 13.7278 25.4343C13.6972 25.4012 13.6711 25.3702 13.6582 25.3459L12.0593 23.7052C11.5969 23.2319 10.8365 23.2212 10.3626 23.6829C9.88762 24.1457 9.87822 24.9057 10.3407 25.381L13.9022 29.0354C14.1444 29.2842 14.4687 29.4053 14.7905 29.3973C15.0825 29.39 15.3724 29.2773 15.5985 29.0567L19.2536 25.4952C19.7286 25.033 19.7384 24.2727 19.2759 23.7977ZM14.832 27.0419C14.8462 27.0415 14.8601 27.0405 14.8741 27.0391C14.8608 27.0555 14.8473 27.0555 14.832 27.0419Z"
            :fill="WEBAPPPRO_ICON_COLOR_INFO_2"
          />
          <path
            d="M40.6592 26.994L37.0984 23.3393C36.8562 23.0912 36.5316 22.9697 36.2097 22.9774C35.9177 22.9844 35.6272 23.0981 35.4017 23.3181L31.7459 26.8796C31.2713 27.3427 31.2612 28.103 31.724 28.5777C32.1864 29.0509 32.9464 29.0631 33.4203 28.5996L34.9201 27.1395C34.9493 27.0953 35.021 27.0292 35.1049 26.9572C34.7889 31.4864 31.4143 35.1937 26.8583 35.8886C23.3197 36.4276 19.777 34.9884 17.613 32.1319C17.3757 31.8194 16.9877 31.6621 16.5997 31.7212C16.4257 31.7473 16.2583 31.818 16.1167 31.9255C15.8042 32.1625 15.6466 32.5508 15.7057 32.9388C15.7325 33.1131 15.8035 33.2802 15.9104 33.4218C18.5483 36.9033 22.8663 38.6574 27.1805 37.9994C32.7978 37.1427 36.9512 32.5306 37.254 26.9227C37.2936 26.9627 37.3267 27.001 37.342 27.0299L38.9409 28.6706C39.4037 29.1438 40.1637 29.1546 40.6369 28.6928C41.1126 28.2297 41.122 27.4697 40.6592 26.994Z"
            :fill="WEBAPPPRO_ICON_COLOR_INFO_2"
          />
        </svg> -->

        <svg
          class="mt-3"
          v-show="!isPhotoTaken"
          @click="nextCamera"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="51"
            height="51"
            rx="25.5"
            :fill="WEBAPPPRO_BUTTON_COLOR_2"
            stroke="#1D1D1B"
          />
          <path
            d="M19.2759 23.7977C18.8138 23.3238 18.0535 23.3127 17.5792 23.7758L16.0805 25.2356C16.0488 25.2832 15.9684 25.356 15.8762 25.435C16.1598 20.872 19.5456 17.1275 24.1267 16.4294C27.6648 15.8897 31.2075 17.3293 33.3723 20.1861C33.6096 20.4983 33.9976 20.6559 34.3859 20.5964C34.5599 20.57 34.7269 20.4993 34.8686 20.3921C35.1807 20.1548 35.3383 19.7672 35.2799 19.3792C35.2531 19.2042 35.1821 19.0375 35.0749 18.8959C32.4373 15.4144 28.1189 13.6603 23.8051 14.3179C18.1739 15.1767 14.0135 19.8082 13.7278 25.4343C13.6972 25.4012 13.6711 25.3702 13.6582 25.3459L12.0593 23.7052C11.5969 23.2319 10.8365 23.2212 10.3626 23.6829C9.88762 24.1457 9.87822 24.9057 10.3407 25.381L13.9022 29.0354C14.1444 29.2842 14.4687 29.4053 14.7905 29.3973C15.0825 29.39 15.3724 29.2773 15.5985 29.0567L19.2536 25.4952C19.7286 25.033 19.7384 24.2727 19.2759 23.7977ZM14.832 27.0419C14.8462 27.0415 14.8601 27.0405 14.8741 27.0391C14.8608 27.0555 14.8473 27.0555 14.832 27.0419Z"
            :fill="WEBAPPPRO_BUTTON_COLOR_1"
          />
          <path
            d="M40.6592 26.9943L37.0984 23.3395C36.8562 23.0914 36.5316 22.9699 36.2097 22.9776C35.9177 22.9846 35.6272 23.0984 35.4017 23.3183L31.7459 26.8798C31.2713 27.3429 31.2612 28.1032 31.724 28.5779C32.1864 29.0511 32.9464 29.0633 33.4203 28.5998L34.9201 27.1397C34.9493 27.0955 35.021 27.0294 35.1049 26.9574C34.7889 31.4866 31.4143 35.1939 26.8583 35.8888C23.3197 36.4278 19.777 34.9886 17.613 32.1321C17.3757 31.8196 16.9877 31.6623 16.5997 31.7215C16.4257 31.7476 16.2583 31.8182 16.1167 31.9257C15.8042 32.1627 15.6466 32.551 15.7057 32.939C15.7325 33.1133 15.8035 33.2804 15.9104 33.422C18.5483 36.9035 22.8663 38.6576 27.1805 37.9996C32.7978 37.1429 36.9512 32.5308 37.254 26.9229C37.2936 26.9629 37.3267 27.0012 37.342 27.0301L38.9409 28.6708C39.4037 29.144 40.1637 29.1548 40.6369 28.6931C41.1126 28.2299 41.122 27.4699 40.6592 26.9943Z"
            :fill="WEBAPPPRO_BUTTON_COLOR_1"
          />
        </svg>
        <div
          v-if="isCameraOpen && !isLoading && !isPhotoTaken"
          class="camera-shoot ml-2"
        >
          <v-btn
            @click="takePhoto"
            :color="WEBAPPPRO_BUTTON_COLOR_1"
            class="taking mt-3"
            :style="{
              color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
              borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
              'text-transform': 'none',
              padding: '25px 100px',
              'font-weight': 'bold',
              'line-height': '18px',
              'letter-spacing': '0em',
              'text-align': 'center',
              width: '271px',
              height: '52px',
            }"
          >
            <span class="text-center">Je prends ma photo</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="errorDetected !== ''" class="justify-center">
      <v-alert color="red" background-color="white" type="error">
        <template v-slot:icon>
          <v-icon color="red">$warning</v-icon>
        </template>
        {{ errorDetected }}
      </v-alert>
    </div>

    <div
      v-if="isCameraOpen && !isLoading && isPhotoTaken && !downloadLink"
      class="mt-3 text-center align-middle"
      style="height: 300px"
    >
      <v-progress-circular
        :color="WEBAPPPRO_BUTTON_COLOR_1"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
      <br />
      <span :style="{ color: WEBAPPPRO_BUTTON_COLOR_1 }"
        >Veuillez patienter...</span
      >
    </div>
    <!-- Button valid photo -->
    <div
      v-if="isCameraOpen && !isLoading && isPhotoTaken"
      class="camera-shoot justify-center d-flex mb-4 align-center"
    >
      <v-btn
        @click="takePhoto"
        :color="WEBAPPPRO_BUTTON_COLOR_2"
        class="centered-button"
        :style="{
          color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
          boxShadow: WEBAPPPRO_BUTTON_SHADOW_2,
          borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
          'text-transform': 'none',
          padding: '25px 100px',
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
          'font-weight': 'bold',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          marginTop: '10px',
          height: '52px',
          width: '327px',
        }"
      >
        Je reprends ma photo
      </v-btn>
    </div>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
import TipsPhoto from '../views/User/TipsPhoto.vue'

export default {
  name: 'PhotoShooter',
  components: {
    TipsPhoto,
  },
  data() {
    return {
      //carouselIndex: 0,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      videoWidth: 0,
      videoHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
      videoDevices: [],
      frontCamera: false,
      maskHeight: 0,
      currentIndexCamera: 0,
      selfiMode: true,
      imageCapture: null,
      masktop: '0px',
      processingInProgress: false,
      errorDetected: '',
      imageDetouredCoefDimension: 1,
      rejectReasons: [],
      errorInterval: null,
      errorIndex: 0,
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_2',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      fontSize2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      fontSize3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      borderRound: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
      downloadLink: null,
      isLoadingPhotoMessageVisible: false,
    }
  },
  computed: {
    photoReady() {
      return this.isPhotoTaken && this.isCameraOpen
    },
    carouselIndex() {
      return this.$store.state.carouselIndex
    },
  },
  methods: {
    previousSlide() {
      if (this.carouselIndex > 0) {
        const newIndex = this.carouselIndex - 1
        this.$store.commit('setCarouselIndex', newIndex)
        //console.log('carouselIndex mis à jour:', newIndex);
      }
    },
    toggleCamera() {
      this.processingInProgress = false
      this.errorDetected = ''
      if (this.isCameraOpen == false) {
        this.isCameraOpen = true
        this.startCamera()
      } else {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.isShotPhoto = false
        this.stopCameraStream()
      }
    },
    stopCameraStream() {
      if (this.$refs && this.$refs.camera && this.$refs.camera.srcObject) {
        let tracks = this.$refs.camera.srcObject.getTracks()
        tracks.forEach(track => {
          track.stop()
        })
      }
    },
    createCameraElement() {
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // First get ahold of the legacy getUserMedia, if present
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia
          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(
              new Error("La caméra n'est pas disponible sur votre navigateur."),
            )
          }
          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      this.gotDevices()
      // Listen to the window's size changes
      window.addEventListener('resize', checkForChanges)
      function checkForChanges() {
        //resize canvas when windows dimension change
        this.stopCameraStream()
        this.startCamera()
      }
      window.addEventListener('beforeunload', () => {
        this.stopCameraStream()
      })
      window.addEventListener('orientationchange', () => {
        this.stopCameraStream()
        this.startCamera()
      })
    },
    gotDevices() {
      // List cameras
      const videoDevices = []
      function devices(mediaDevices) {
        mediaDevices.forEach(function (device) {
          if (device.kind == 'videoinput') {
            videoDevices.push(device.deviceId)
          }
        })
      }
      let promiseDevices = navigator.mediaDevices
        .enumerateDevices()
        .then(devices)
      //Result Fetch
      return Promise.all([promiseDevices])
        .then(() => {
          this.videoDevices = videoDevices
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            "Erreur lors de la demande d'ouverture de la caméra",
          )
        })
    },
    startCamera() {
      this.processingInProgress = false
      this.errorDetected = ''
      if (this.videoDevices.length == 0 || !this.isCameraOpen) {
        return
      }
      this.isLoading = true
      this.videoWidth = 0
      this.videoHeight = 0
      this.maskHeight = 0
      const constraints = (window.constraints = {
        video: {
          width: 3000,
          facingMode: this.selfiMode ? 'user' : 'environment',
          focusMode: 'auto',
        },
      })

      // width: 3000,
      // frameRate: { ideal: 60, max: 120 },
      // aspectRatio: { ideal: 45 / 35 },
      // resizeMode: 'crop-and-scale',
      // zoom: 1,
      // focusMode: 'auto',
      // deviceId: { exact: this.videoDevices[this.currentIndexCamera] },

      this.currentIndexCamera =
        (this.currentIndexCamera + 1) % this.videoDevices.length
      this.selfiMode = !this.selfiMode
      let videoWidth = 0
      let videoHeight = 0
      let imageWidth = 0
      let imageHeight = 0
      let frontCamera = false
      let isLoading = true
      let imageCapture = null
      let promiseUserMedia = navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream
          window.stream = stream
          let stream_settings = stream.getVideoTracks()[0].getSettings()
          imageWidth = stream_settings.width
          imageHeight = stream_settings.height
          // let widthTmp = stream_settings.width
          //let heightTmp = stream_settings.height
          videoHeight = window.screen.height - 250
          videoWidth = window.screen.width - 30
          //videoWidth = Math.min(413.4, (window.screen.width * 50) / 100)
          //videoHeight = ((videoWidth * heightTmp) / widthTmp) * 1.5
          //let track = stream.getVideoTracks()[0]
          //imageCapture = new ImageCapture(track)
          frontCamera = stream_settings.facingMode == 'user'
          isLoading = false
        })
      //Result Fetch
      Promise.all([promiseUserMedia])
        .then(() => {
          if (this.videoDevices[this.currentIndexCamera] == '') {
            this.gotDevices()
          }
          this.isLoading = isLoading
          this.videoWidth = videoWidth
          this.videoHeight = videoHeight
          this.imageWidth = imageWidth
          this.imageHeight = imageHeight
          this.frontCamera = frontCamera
          this.imageCapture = imageCapture
          this.maskHeight = Math.max(
            videoHeight / (1.5 + (this.frontCamera ? 0 : 0.3)),
            ((videoWidth / (1.5 + (this.frontCamera ? 0 : 0.5))) * 45) / 35,
          )
          this.masktop = ((this.videoHeight - this.maskHeight) / 5) * 2 + 'px'
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            "Erreur lors de la demande d'ouverture de la caméra",
          )
        })

      //send video stream result in canvas to show the final result to the user
    },
    //Change camera if possible
    nextCamera() {
      this.stopCameraStream()
      this.startCamera()
    },
    //Take the photo or relaunch the camera
    takePhoto() {
      // Commencez le chargement de la photo
      this.isLoadingPhotoMessageVisible = true

      // Démarrez la caméra si ce n'est pas déjà le cas
      if (this.isPhotoTaken) {
        this.stopCameraStream()
        this.startCamera()
        this.isPhotoTaken = !this.isPhotoTaken
        this.downloadLink = null
        if (this.errorInterval !== null) {
          clearInterval(this.errorInterval)
        }
        this.errorDetected = ''
        this.rejectReasons = []
        this.isLoadingPhotoMessageVisible = false
        return
      }

      // Capturez la photo et dessinez-la sur le canevas
      this.drawCanvas(
        this.$refs.realCanvas,
        this.$refs.canvas,
        this.$refs.camera,
      )

      // Indiquez que la photo a été prise
      this.isPhotoTaken = !this.isPhotoTaken

      // Changez l'index de la caméra pour passer à la prochaine caméra disponible
      this.currentIndexCamera =
        (this.currentIndexCamera - 1 + this.videoDevices.length) %
        this.videoDevices.length

      this.isLoadingPhotoMessageVisible = false
    },

    drawCanvas(canvas, canvasMini, img) {
      this.imageHeight = (this.imageWidth * img.videoHeight) / img.videoWidth
      canvas.width = this.imageWidth
      canvas.height = this.imageHeight
      canvas.getContext('2d').fillStyle = 'white'
      canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height)
      canvas
        .getContext('2d')
        .drawImage(img, 0, 0, this.imageWidth, this.imageHeight)
      canvasMini.src = canvas.toDataURL() //('image/png')

      // Clone canvas for photo resize and detour
      var cloneCanvas = document
        .getElementById('realPhotoTaken')
        .cloneNode(true)
      cloneCanvas.getContext('2d').fillStyle = 'white'
      cloneCanvas.height = (cloneCanvas.height * 1400) / cloneCanvas.width
      cloneCanvas.width = 1400
      cloneCanvas
        .getContext('2d')
        .fillRect(0, 0, cloneCanvas.width, cloneCanvas.height)
      cloneCanvas
        .getContext('2d')
        .drawImage(img, 0, 0, cloneCanvas.width, cloneCanvas.height)

      // Photo resize
      var canvasUrl = document
        .getElementById('realPhotoTaken')
        .toDataURL('image/jpeg', 1)
      var file = this.dataURLtoBlob(canvasUrl)
      var size = file.size
      var i = 2
      while (size / 1000000 > 2) {
        canvasUrl = document
          .getElementById('realPhotoTaken')
          .toDataURL('image/jpeg', 1 / i)
        file = this.dataURLtoBlob(canvasUrl)
        size = file.size
        i = i + 1
      }

      // Prepare parameters for /resize-optimized endpoint
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'photo')
      formData.append('do_apply_detouring', '1')
      formData.append('app_version', '3')
      formData.append('priceId', localStorageService.getValue('priceId'))

      // Send request to /resize-optimized endpoint
      this.processingInProgress = true
      this.errorDetected = ''
      var imageDetouredCoefDimension = 1
      requestService
        .post(
          `/file/resize-optimized`,
          formData,
          null,
          null,
          null,
          'arraybuffer',
        )
        .then(response => {
          //anomalies = response.headers.anomalies;
          const image64 = btoa(
            String.fromCharCode(...new Uint8Array(response.data)),
          )
          canvasMini.src = 'data:image/jpeg;base64,' + image64
          imageDetouredCoefDimension = 0.6

          // Convertir ArrayBuffer en chaîne de caractères
          const responseString = new TextDecoder().decode(response.data)
          // Convertir la chaîne de caractères JSON en objet JavaScript
          const responseObject = JSON.parse(responseString)
          const uidFromResizeOptimized = responseObject.image_uid
          localStorageService.setObject('photoUid', uidFromResizeOptimized)

          const timeouts = []
          for (let index = 1; index < 5; index++) {
            timeouts.push(
              setTimeout(() => {
                requestService
                  .get(`/file/return-uid/${uidFromResizeOptimized}`)
                  .then(returnUidResponse => {
                    if (
                      returnUidResponse.data.success == true &&
                      (returnUidResponse.data.data.detoured_image_uid != null ||
                        returnUidResponse.data.data.resized_image_uid != null)
                    ) {
                      const detouredImageUid =
                        returnUidResponse.data.data.detoured_image_uid
                      const resizedImageUid =
                        returnUidResponse.data.data.resized_image_uid
                      this.errorIndex = 0
                      this.rejectReasons = returnUidResponse.data.data.anomalies

                      if (this.rejectReasons.length > 0) {
                        this.errorDetected =
                          this.rejectReasons[this.errorIndex].long_text
                        this.errorInterval = setInterval(() => {
                          this.errorIndex =
                            (this.errorIndex + 1) % this.rejectReasons.length
                          this.errorDetected =
                            this.rejectReasons[this.errorIndex].long_text
                        }, 5000)
                      }
                      this.processingInProgress = false
                      this.imageDetouredCoefDimension =
                        imageDetouredCoefDimension
                      if (detouredImageUid) {
                        // Utiliser la fonction getImageDownloadAnonymouslyLink pour obtenir le lien de téléchargement de l'image détournée
                        this.downloadLink =
                          requestService.getImageDownloadAnonymouslyLink(
                            detouredImageUid,
                          )
                      } else if (resizedImageUid) {
                        // Utiliser la fonction getImageDownloadAnonymouslyLink pour obtenir le lien de téléchargement de l'image recadrée
                        this.downloadLink =
                          requestService.getImageDownloadAnonymouslyLink(
                            resizedImageUid,
                          )
                      } else if (uidFromResizeOptimized) {
                        // Utiliser la fonction getImageDownloadAnonymouslyLink pour obtenir le lien de téléchargement de l'image initiale
                        this.downloadLink =
                          requestService.getImageDownloadAnonymouslyLink(
                            uidFromResizeOptimized,
                          )
                      } else {
                        console.error("L'image n'est pas disponible.")
                      }

                      timeouts.forEach(item => {
                        clearTimeout(item)
                      }, console.log('hello'))
                    }
                  })
              }, 5000 * index),
            )
          }
        })
    },

    //save the result in the stor
    save() {
      var canvasUrl = document
        .getElementById('realPhotoTaken')
        .toDataURL('image/jpeg', 1)
      var file = this.dataURLtoBlob(canvasUrl)
      var size = file.size
      var i = 2
      while (size / 1000000 > 2) {
        canvasUrl = document
          .getElementById('realPhotoTaken')
          .toDataURL('image/jpeg', 1 / i)
        file = this.dataURLtoBlob(canvasUrl)
        size = file.size
        i = i + 1
      }
      localStorageService.setObject('photoUrl', canvasUrl)
    },
    dataURLtoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(',')[1])
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length)
      // create a view into the buffer
      var ia = new Uint8Array(ab)
      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString })
      return blob
    },
  },
  watch: {
    photoReady(value) {
      this.$emit('photoReady', value)
    },
  },
  beforeDestroy() {
    this.stopCameraStream()
  },
  mounted() {
    this.isCameraOpen = false
    this.videoWidth = 0
    this.videoHeight = 0
    this.videoDevices = []
    this.currentIndexCamera = 0
    this.createCameraElement()
  },
}
</script>

<style scoped lang="scss">
.background-container {
  /*background-size: cover;*/
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
}
.adjust {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.web-camera-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .camera-button {
    margin-bottom: 1rem;
  }

  .camera-change {
    margin: 1rem;
  }

  .camera-box {
    position: relative;

    .camera-shutter {
      opacity: 0;
      width: 100%;
      height: auto;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }

    .mask {
      position: absolute;
    }
  }

  .camera-shoot {
    margin: 1rem;
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }

  .text-clignote {
    animation-duration: 1.7s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
    padding: 5px 50px;
    margin-top: 20px;
    background: #d7d7d74a;
    color: #b30303;
    font-size: larger;
  }

  @keyframes clignoter {
    0% {
      opacity: 0.9;
    }

    40% {
      opacity: 0;
    }

    100% {
      opacity: 0.9;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 360px) and (max-height: 639px) {
  .parti {
    width: 120px !important;
  }
  .taking {
    width: 242px !important;
  }
  .centered-button {
    width: 290px !important;
  }
  .adjust {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
@media screen and (min-width: 760px) and (max-width: 1024px) and (min-height: 1024px) {
  .adjust {
    display: flex;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    position: absolute;
    bottom: 15%;
    width: 100%;
  }
  .camera-box {
    position: relative;
  }
  .camera-shutter {
    position: relative;
  }
}
</style>
Footer © 2023 GitHub, Inc. Footer navigation Terms Privacy Security Status Docs
Contact GitHub Pricing API Training Blog About webapp/PhotoShooter.vue at master
· smartphone-id/webapp
