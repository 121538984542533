import router from '../router/index.js'
import axios from 'axios'
import store from '@/store/index'

const roleList = {
  sorter: 'ROLE_SORTER',
  admin: 'ROLE_ADMIN',
  controller: 'ROLE_CONTROLLER',
  postman: 'ROLE_POSTMAN',
  superAdmin: 'ROLE_SUPER_ADMIN',
  orderManager: 'ROLE_ORDER_MANAGER',
  financeManager: 'ROLE_FINANCE_MANAGER',
  userManager: 'ROLE_USER_MANAGER',
}

const user = JSON.parse(localStorage.getItem('user'))
const state = user
  ? {
      status: {
        loggedIn: true,
      },
      user: user,
    }
  : {
      status: {},
      user: null,
    }
const actions = {
  login({ commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      axios
        .post(window.VUE_APP_API_URL + '/login_check', {
          username: username,
          password: password,
        })
        .then(response => {
          if (response.data.token) {
            commit('loginSuccess', { username, ...response.data })
            localStorage.setItem(
              'user',
              JSON.stringify({ username, ...response.data }),
            )
            resolve(response.data)
            router.push('/')
          }
        })
        .catch(error => {
          commit('loginFailure', error)
          reject(error)
        })
    })
  },
  logout({ commit }) {
    localStorage.removeItem('user')
    commit('logout')
    router.push({
      name: router.currentRoute.meta.NotAuthorizedRoute,
      params: {
        partner_uid: router.currentRoute.params?.partner_uid,
      },
    })
  },
  refreshToken({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post(window.VUE_APP_API_URL + '/token/refresh', {
          refresh_token: state.user.refresh_token,
        })
        .then(response => {
          if (response.data.token) {
            const token = response.data.token
            commit('saveToken', token)
            localStorage.setItem('user', JSON.stringify(state.user))
            resolve(response.data)
          }
        })
        .catch(error => {
          // router.push({name: 'Authentication', params: {
          //   partner_uid: router.currentRoute.params?.partner_uid,
          // },})
          store.dispatch('alert/error', 'Connexion expirée')
          commit('loginFailure', error)
          reject(error)
        })
    })
  },
  isGranted({ state }, roles) {
    if (!state.user || !state.user.roles) {
      return false
    }
    if (typeof roles === 'string') {
      return state.user.roles.includes(roleList[roles])
    }
    for (const role of roles) {
      if (state.user.roles.includes(roleList[role])) {
        return true
      }
    }
    return false
  },
}

const mutations = {
  loginRequest(state, user) {
    state.status = {
      loggingIn: true,
    }
    state.user = user
  },
  loginSuccess(state, user) {
    state.status = {
      loggedIn: true,
    }
    state.user = user
  },
  saveToken(state, token) {
    state.user.token = token
  },
  loginFailure(state) {
    state.status = {}
    state.user = null
  },
  logout(state) {
    state.status = {}
    state.user = null
  },
}

const getters = {
  status: state => state.status,
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
