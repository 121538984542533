/*********************************************** object ***************************************/
/**
 * save the  in the object
 * @param key
 * @param value
 */
function setObject(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * return an object
 * @returns ShortliveApiKey
 */
function getValue(key) {
  const val = localStorage.getItem(key)
  if (val === 'null' || val === null || val === 'undefined') {
    return undefined
  }
  return JSON.parse(val)
}
/**
 * Removes all key/value pairs, if there are any.
 * Dispatches a storage event on Window objects holding an equivalent Storage object.
 */
function clearAll() {
  localStorage.clear()
}

/**
 *
 */
function getStyle(hostname, key) {
  const logo = getValue('logo')
  const primaryColor = getValue('primaryColor')
  const secondaryColor = getValue('secondaryColor')
  const color1 = getValue('color1')
  const color2 = getValue('color2')
  const color3 = getValue('color3')
  const color4 = getValue('color4')
  const color5 = getValue('color5')

  const WEBAPPPRO_BACKGROUND_DESKTOP = getValue('WEBAPPPRO_BACKGROUND_DESKTOP')
  const WEBAPPPRO_BACKGROUND_MOBILE = getValue('WEBAPPPRO_BACKGROUND_MOBILE')
  const WEBAPPPRO_LOGO_2 = getValue('WEBAPPPRO_LOGO_2')
  const WEBAPPPRO_LOGO_1 = getValue('WEBAPPPRO_LOGO')
  const WEBAPPPRO_CARTE = getValue('WEBAPPPRO_CARTE')
  const WEBAPPPRO_CARD_COLOR = getValue('WEBAPPPRO_CARD_COLOR')
  const WEBAPPPRO_ICON_QRCODE_SCREEN = getValue('WEBAPPPRO_ICON_QRCODE_SCREEN')
  const WEBAPPPRO_ICON_COLOR_WARNING = getValue('WEBAPPPRO_ICON_COLOR_WARNING')
  const WEBAPPPRO_ICON_COLOR_INFO = getValue('WEBAPPPRO_ICON_COLOR_INFO')
  const WEBAPPPRO_COLOR_1 = getValue('WEBAPPPRO_COLOR_1')
  const WEBAPPPRO_COLOR_2 = getValue('WEBAPPPRO_COLOR_2')
  const WEBAPPPRO_COLOR_3 = getValue('WEBAPPPRO_COLOR_3')
  const WEBAPPPRO_COLOR_4 = getValue('WEBAPPPRO_COLOR_4')
  const WEBAPPPRO_COLOR_5 = getValue('WEBAPPPRO_COLOR_5')
  const WEBAPPPRO_BUTTON_COLOR_1 = getValue('WEBAPPPRO_BUTTON_COLOR_1')
  const WEBAPPPRO_BUTTON_COLOR_2 = getValue('WEBAPPPRO_BUTTON_COLOR_2')
  const WEBAPPPRO_TEXT_COLOR_1 = getValue('WEBAPPPRO_TEXT_COLOR_1')
  const WEBAPPPRO_TEXT_COLOR_2 = getValue('WEBAPPPRO_TEXT_COLOR_2')
  const WEBAPPPRO_TEXT_COLOR_3 = getValue('WEBAPPPRO_TEXT_COLOR_3')
  const WEBAPPPRO_TEXT_COLOR_4 = getValue('WEBAPPPRO_TEXT_COLOR_4')
  const WEBAPPPRO_BUTTON_TEXT_COLOR_1 = getValue(
    'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
  )
  const WEBAPPPRO_BUTTON_TEXT_COLOR_2 = getValue(
    'WEBAPPPRO_BUTTON_TEXT_COLOR_2',
  )
  const WEBAPPPRO_FONT_FAMILY_1 = getValue('WEBAPPPRO_FONT_FAMILY_1')
  const WEBAPPPRO_FONT_FAMILY_2 = getValue('WEBAPPPRO_FONT_FAMILY_2')
  const WEBAPPPRO_FONT_FAMILY_3 = getValue('WEBAPPPRO_FONT_FAMILY_3')
  const WEBAPPPRO_FONT_SIZE_1 = getValue('WEBAPPPRO_FONT_SIZE_1')
  const WEBAPPPRO_FONT_SIZE_2 = getValue('WEBAPPPRO_FONT_SIZE_2')
  const WEBAPPPRO_FONT_SIZE_3 = getValue('WEBAPPPRO_FONT_SIZE_3')
  const WEBAPPPRO_FONT_SIZE_TIPS = getValue('WEBAPPPRO_FONT_SIZE_TIPS')
  const WEBAPPPRO_BUTTON_FONT_SIZE = getValue('WEBAPPPRO_BUTTON_FONT_SIZE')
  const WEBAPPPRO_CARD_BORDER_ROUND = getValue('WEBAPPPRO_CARD_BORDER_ROUND')
  const WEBAPPPRO_BUTTON_BORDER_ROUND = getValue(
    'WEBAPPPRO_BUTTON_BORDER_ROUND',
  )
  const WEBAPPPRO_QRCODE_BORDER_COLOR = getValue(
    'WEBAPPPRO_QRCODE_BORDER_COLOR',
  )
  const WEBAPPPRO_DRAWER_COLOR = getValue('WEBAPPPRO_DRAWER_COLOR')
  const WEBAPPPRO_CARD_HOME_BORDER_ROUND = getValue(
    'WEBAPPPRO_CARD_HOME_BORDER_ROUND',
  )
  const WEBAPPPRO_COLOR_CARD_HOME = getValue('WEBAPPPRO_COLOR_CARD_HOME')
  const WEBAPPPRO_CARD_BO_COLOR = getValue('WEBAPPPRO_CARD_BO_COLOR')
  const WEBAPPPRO_COLOR_1_FILL_ICON = getValue('WEBAPPPRO_COLOR_1_FILL_ICON')
  const WEBAPPPRO_COLOR_2_FILL_ICON = getValue('WEBAPPPRO_COLOR_2_FILL_ICON')
  const WEBAPPPRO_COLOR_SKIRT = getValue('WEBAPPPRO_COLOR_SKIRT')
  const WEBAPPPRO_COLOR_GIRL_TOP = getValue('WEBAPPPRO_COLOR_GIRL_TOP')
  const WEBAPPPRO_COLOR_HAIR = getValue('WEBAPPPRO_COLOR_HAIR')
  const WEBAPPPRO_TEXT_COLOR_ETAPES = getValue('WEBAPPPRO_TEXT_COLOR_ETAPES')
  const WEBAPPPRO_INPUT_TEXT_COLOR = getValue('WEBAPPPRO_INPUT_TEXT_COLOR')
  const WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT = getValue(
    'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT',
  )
  const WEBAPPPRO_FILL_COLOR_FOR_TREATMENT = getValue(
    'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT',
  )
  const WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT = getValue(
    'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT',
  )
  const WEBAPPPRO_ALLOW_WALLET = getValue('WEBAPPPRO_ALLOW_WALLET')
  const WEBAPPPRO_QRCODE_SHADOW = getValue('WEBAPPPRO_QRCODE_SHADOW')
  const WEBAPPPRO_BUTTON_SHADOW_1 = getValue('WEBAPPPRO_BUTTON_SHADOW_1')
  const WEBAPPPRO_BUTTON_SHADOW_2 = 'WEBAPPPRO_BUTTON_SHADOW_2'
  const WEBAPPPRO_CARD_SHADOW_1 = 'WEBAPPPRO_CARD_SHADOW_1'
  const WEBAPPPRO_CARD_SHADOW_2 = 'WEBAPPPRO_CARD_SHADOW_2'
  const WEBAPPPRO_CARD_SHADOW_3 = getValue('WEBAPPPRO_CARD_SHADOW_3')
  const WEBAPPPRO_ICON_COLOR_INFO_2 = getValue('WEBAPPPRO_ICON_COLOR_INFO_2')
  const WEBAPPPRO_ICON_COLOR_WARNING_2 = getValue(
    'WEBAPPPRO_ICON_COLOR_WARNING_2',
  )
  const WEBAPPPRO_COLOR_FOR_TEXT_BO = getValue('WEBAPPPRO_COLOR_FOR_TEXT_BO')
  const WEBAPPPRO_COLOR_FOR_ICON_BO = getValue('WEBAPPPRO_COLOR_FOR_ICON_BO')

  const styles = {
    default: {
      icon:
        logo !== undefined
          ? logo
          : 'https://storage.googleapis.com/smid-static/icon-smartphoneid.png',
      iconWidth: 163,
      iconColor: primaryColor !== undefined ? primaryColor : '#000',
      cardColor: secondaryColor !== undefined ? secondaryColor : '#b2b2b2',
      titleStyle:
        'color:' + (primaryColor !== undefined ? primaryColor : '#000'),
      timelineColor: primaryColor !== undefined ? primaryColor : '#000000',
      buttonValidColor: primaryColor !== undefined ? primaryColor : '#000000',
      buttonTextValidColor: '#ffffff',
      buttonNeutralolor:
        secondaryColor !== undefined ? secondaryColor : '#b2b2b2',
      buttonTextNeutralColor:
        primaryColor !== undefined ? primaryColor : '#000000',
      color1: color1 !== undefined ? color1 : '#2D4490',
      color2: color2 !== undefined ? color2 : '#2D4490',
      color3: color3 !== undefined ? color3 : '#2D4490',
      color4: color4 !== undefined ? color4 : '#313B82',
      color5: color5 !== undefined ? color5 : '#E4E4E4',

      WEBAPPPRO_BACKGROUND_DESKTOP:
        WEBAPPPRO_BACKGROUND_DESKTOP !== undefined
          ? WEBAPPPRO_BACKGROUND_DESKTOP
          : 'BG.svg',
      WEBAPPPRO_BACKGROUND_MOBILE:
        WEBAPPPRO_BACKGROUND_MOBILE !== undefined
          ? WEBAPPPRO_BACKGROUND_MOBILE
          : 'BG.svg',
      WEBAPPPRO_LOGO_2:
        WEBAPPPRO_LOGO_2 !== undefined ? WEBAPPPRO_LOGO_2 : null,
      WEBAPPPRO_LOGO_1:
        WEBAPPPRO_LOGO_1 !== undefined
          ? WEBAPPPRO_LOGO_1
          : 'https://storage.googleapis.com/smid-static/icon-smartphoneid.png',
      WEBAPPPRO_CARTE:
        WEBAPPPRO_CARTE !== undefined
          ? WEBAPPPRO_CARTE
          : 'https://storage.googleapis.com/smid-static/iDcard_smid.jpg',
      WEBAPPPRO_ICON_QRCODE_SCREEN:
        WEBAPPPRO_ICON_QRCODE_SCREEN !== undefined
          ? WEBAPPPRO_ICON_QRCODE_SCREEN
          : null,
      WEBAPPPRO_COLOR_1:
        WEBAPPPRO_COLOR_1 !== undefined ? WEBAPPPRO_COLOR_1 : '#ffffff',
      WEBAPPPRO_COLOR_2:
        WEBAPPPRO_COLOR_2 !== undefined ? WEBAPPPRO_COLOR_2 : '#b2b2b2',
      WEBAPPPRO_COLOR_3:
        WEBAPPPRO_COLOR_3 !== undefined ? WEBAPPPRO_COLOR_3 : '#ffffff',
      WEBAPPPRO_COLOR_4:
        WEBAPPPRO_COLOR_4 !== undefined ? WEBAPPPRO_COLOR_4 : '#ffffff',
      WEBAPPPRO_COLOR_5:
        WEBAPPPRO_COLOR_5 !== undefined ? WEBAPPPRO_COLOR_5 : '#000000',
      WEBAPPPRO_ICON_COLOR_INFO:
        WEBAPPPRO_ICON_COLOR_INFO !== undefined
          ? WEBAPPPRO_ICON_COLOR_INFO
          : '#000000',
      WEBAPPPRO_ICON_COLOR_WARNING:
        WEBAPPPRO_ICON_COLOR_WARNING !== undefined
          ? WEBAPPPRO_ICON_COLOR_WARNING
          : '#E1E1E1',
      WEBAPPPRO_BUTTON_COLOR_1:
        WEBAPPPRO_BUTTON_COLOR_1 !== undefined
          ? WEBAPPPRO_BUTTON_COLOR_1
          : '#000000',
      WEBAPPPRO_BUTTON_COLOR_2:
        WEBAPPPRO_BUTTON_COLOR_2 !== undefined
          ? WEBAPPPRO_BUTTON_COLOR_2
          : '#E1E1E1',

      WEBAPPPRO_TEXT_COLOR_1:
        WEBAPPPRO_TEXT_COLOR_1 !== undefined
          ? WEBAPPPRO_TEXT_COLOR_1
          : '#000000',
      WEBAPPPRO_TEXT_COLOR_2:
        WEBAPPPRO_TEXT_COLOR_2 !== undefined
          ? WEBAPPPRO_TEXT_COLOR_2
          : '#000000',
      WEBAPPPRO_TEXT_COLOR_3:
        WEBAPPPRO_TEXT_COLOR_3 !== undefined
          ? WEBAPPPRO_TEXT_COLOR_3
          : '#000000',
      WEBAPPPRO_TEXT_COLOR_4:
        WEBAPPPRO_TEXT_COLOR_4 !== undefined
          ? WEBAPPPRO_TEXT_COLOR_4
          : '#000000',
      WEBAPPPRO_BUTTON_TEXT_COLOR_1:
        WEBAPPPRO_BUTTON_TEXT_COLOR_1 !== undefined
          ? WEBAPPPRO_BUTTON_TEXT_COLOR_1
          : '#ffffff',
      WEBAPPPRO_BUTTON_TEXT_COLOR_2:
        WEBAPPPRO_BUTTON_TEXT_COLOR_2 !== undefined
          ? WEBAPPPRO_BUTTON_TEXT_COLOR_2
          : '#7A7A7A',

      WEBAPPPRO_FONT_FAMILY_1:
        WEBAPPPRO_FONT_FAMILY_1 !== undefined
          ? WEBAPPPRO_FONT_FAMILY_1
          : 'ARIAL',
      WEBAPPPRO_FONT_FAMILY_2:
        WEBAPPPRO_FONT_FAMILY_2 !== undefined
          ? WEBAPPPRO_FONT_FAMILY_2
          : 'ARIAL',
      WEBAPPPRO_FONT_FAMILY_3:
        WEBAPPPRO_FONT_FAMILY_3 !== undefined
          ? WEBAPPPRO_FONT_FAMILY_3
          : 'ARIAL',
      WEBAPPPRO_FONT_SIZE_1:
        WEBAPPPRO_FONT_SIZE_1 !== undefined ? WEBAPPPRO_FONT_SIZE_1 : '28px',
      WEBAPPPRO_FONT_SIZE_2:
        WEBAPPPRO_FONT_SIZE_2 !== undefined ? WEBAPPPRO_FONT_SIZE_2 : '20px',
      WEBAPPPRO_FONT_SIZE_3:
        WEBAPPPRO_FONT_SIZE_3 !== undefined ? WEBAPPPRO_FONT_SIZE_3 : '14px',
      WEBAPPPRO_FONT_SIZE_TIPS:
        WEBAPPPRO_FONT_SIZE_TIPS !== undefined
          ? WEBAPPPRO_FONT_SIZE_TIPS
          : '18px',
      WEBAPPPRO_BUTTON_FONT_SIZE:
        WEBAPPPRO_BUTTON_FONT_SIZE !== undefined
          ? WEBAPPPRO_BUTTON_FONT_SIZE
          : '15px',
      WEBAPPPRO_CARD_BORDER_ROUND:
        WEBAPPPRO_CARD_BORDER_ROUND !== undefined
          ? WEBAPPPRO_CARD_BORDER_ROUND
          : '20px',
      WEBAPPPRO_CARD_COLOR:
        WEBAPPPRO_CARD_COLOR !== undefined ? WEBAPPPRO_CARD_COLOR : '#ffffff',
      WEBAPPPRO_BUTTON_BORDER_ROUND:
        WEBAPPPRO_BUTTON_BORDER_ROUND !== undefined
          ? WEBAPPPRO_BUTTON_BORDER_ROUND
          : '0px',
      WEBAPPPRO_QRCODE_BORDER_COLOR:
        WEBAPPPRO_QRCODE_BORDER_COLOR !== undefined
          ? WEBAPPPRO_QRCODE_BORDER_COLOR
          : '#000000',
      WEBAPPPRO_QRCODE_SHADOW:
        WEBAPPPRO_QRCODE_SHADOW !== undefined
          ? WEBAPPPRO_QRCODE_SHADOW
          : 'none',
      WEBAPPPRO_BUTTON_SHADOW_1:
        WEBAPPPRO_BUTTON_SHADOW_1 !== undefined
          ? WEBAPPPRO_BUTTON_SHADOW_1
          : 'none',
      WEBAPPPRO_BUTTON_SHADOW_2:
        WEBAPPPRO_BUTTON_SHADOW_2 !== undefined
          ? WEBAPPPRO_BUTTON_SHADOW_2
          : 'none',
      WEBAPPPRO_CARD_SHADOW_1:
        WEBAPPPRO_CARD_SHADOW_1 !== undefined
          ? WEBAPPPRO_CARD_SHADOW_1
          : 'none',
      WEBAPPPRO_CARD_SHADOW_2:
        WEBAPPPRO_CARD_SHADOW_2 !== undefined
          ? WEBAPPPRO_CARD_SHADOW_2
          : 'none',
      WEBAPPPRO_CARD_SHADOW_3:
        WEBAPPPRO_CARD_SHADOW_3 !== undefined
          ? WEBAPPPRO_CARD_SHADOW_3
          : 'none',
      WEBAPPPRO_ICON_COLOR_INFO_2:
        WEBAPPPRO_ICON_COLOR_INFO_2 !== undefined
          ? WEBAPPPRO_ICON_COLOR_INFO_2
          : '#ffffff',
      WEBAPPPRO_ICON_COLOR_WARNING_2:
        WEBAPPPRO_ICON_COLOR_WARNING_2 !== undefined
          ? WEBAPPPRO_ICON_COLOR_WARNING_2
          : '#ffffff',
      WEBAPPPRO_COLOR_FOR_TEXT_BO:
        WEBAPPPRO_COLOR_FOR_TEXT_BO !== undefined
          ? WEBAPPPRO_COLOR_FOR_TEXT_BO
          : '#ffffff',
      WEBAPPPRO_COLOR_FOR_ICON_BO:
        WEBAPPPRO_COLOR_FOR_ICON_BO !== undefined
          ? WEBAPPPRO_COLOR_FOR_ICON_BO
          : '#ffffff',
      WEBAPPPRO_CARD_HOME_BORDER_ROUND:
        WEBAPPPRO_CARD_HOME_BORDER_ROUND !== undefined
          ? WEBAPPPRO_CARD_HOME_BORDER_ROUND
          : '0px 25px 25px 0px',
      WEBAPPPRO_COLOR_CARD_HOME:
        WEBAPPPRO_COLOR_CARD_HOME !== undefined
          ? WEBAPPPRO_COLOR_CARD_HOME
          : '#ffffff',
      WEBAPPPRO_DRAWER_COLOR:
        WEBAPPPRO_DRAWER_COLOR !== undefined
          ? WEBAPPPRO_DRAWER_COLOR
          : '#000000',
      WEBAPPPRO_CARD_BO_COLOR:
        WEBAPPPRO_CARD_BO_COLOR !== undefined
          ? WEBAPPPRO_CARD_BO_COLOR
          : '#000000',
      WEBAPPPRO_COLOR_1_FILL_ICON:
        WEBAPPPRO_COLOR_1_FILL_ICON !== undefined
          ? WEBAPPPRO_COLOR_1_FILL_ICON
          : '#000000',
      WEBAPPPRO_COLOR_2_FILL_ICON:
        WEBAPPPRO_COLOR_2_FILL_ICON !== undefined
          ? WEBAPPPRO_COLOR_2_FILL_ICON
          : '#000000',
      WEBAPPPRO_COLOR_SKIRT:
        WEBAPPPRO_COLOR_SKIRT !== undefined ? WEBAPPPRO_COLOR_SKIRT : '#000000',
      WEBAPPPRO_COLOR_GIRL_TOP:
        WEBAPPPRO_COLOR_GIRL_TOP !== undefined
          ? WEBAPPPRO_COLOR_GIRL_TOP
          : '#000000',
      WEBAPPPRO_COLOR_HAIR:
        WEBAPPPRO_COLOR_HAIR !== undefined ? WEBAPPPRO_COLOR_HAIR : '#000000',
      WEBAPPPRO_TEXT_COLOR_ETAPES:
        WEBAPPPRO_TEXT_COLOR_ETAPES !== undefined
          ? WEBAPPPRO_TEXT_COLOR_ETAPES
          : '#000000',
      WEBAPPPRO_INPUT_TEXT_COLOR:
        WEBAPPPRO_INPUT_TEXT_COLOR !== undefined
          ? WEBAPPPRO_INPUT_TEXT_COLOR
          : '#000000',
      WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT:
        WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT !== undefined
          ? WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT
          : '#000000',
      WEBAPPPRO_FILL_COLOR_FOR_TREATMENT:
        WEBAPPPRO_FILL_COLOR_FOR_TREATMENT !== undefined
          ? WEBAPPPRO_FILL_COLOR_FOR_TREATMENT
          : '#000000',
      WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT:
        WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT !== undefined
          ? WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT
          : '#ffffff',
      WEBAPPPRO_ALLOW_WALLET:
        WEBAPPPRO_ALLOW_WALLET !== undefined ? WEBAPPPRO_ALLOW_WALLET : 'true',
    },

    localhost: {
      icon: 'https://storage.googleapis.com/smid-static/icon-smartphoneid.png',
      iconWidth: 163,
      titleStyle: '#313B82',
      timelineColor: '#c23838',
      buttonValidColor: '#000000',
      buttonTextValidColor: '#ffffff',
      buttonNeutralolor: '#b2b2b2',
      buttonTextNeutralColor: '#000000',
      primaryColor: primaryColor !== undefined ? primaryColor : '#313B82',
      secondaryColor: secondaryColor !== undefined ? secondaryColor : '#D6BFF3',
      color1: '#2D4490',
      color2: '#2D4490',
      color3: '#2D4490',
      color4: '#313B82',
      color5: '#E4E4E4',

      WEBAPPPRO_BACKGROUND_DESKTOP: 'BG_Kopilote_Desktop.svg',
      WEBAPPPRO_BACKGROUND_MOBILE: 'BG_Kopilote_Mobile.svg',
      WEBAPPPRO_LOGO_2: 'Logo_Kopilote.png',
      WEBAPPPRO_LOGO_1:
        'https://storage.googleapis.com/smid-static/icon-smartphoneid.png',
      WEBAPPPRO_CARTE: 'Group 53.jpg',
      WEBAPPPRO_ICON_QRCODE_SCREEN: 'Icon_wallet_Kopilote.png',
      WEBAPPPRO_COLOR_1: '#E1E1E1',
      WEBAPPPRO_COLOR_2: '#b2b2b2',
      WEBAPPPRO_COLOR_3: '#ffffff',
      WEBAPPPRO_COLOR_4: '#ffffff',
      WEBAPPPRO_COLOR_5: '#ffffff',
      WEBAPPPRO_BUTTON_COLOR_1: '#313B82',
      WEBAPPPRO_BUTTON_COLOR_2: '#D6BFF3',
      WEBAPPPRO_FONT_FAMILY_1: 'League Gothic',
      WEBAPPPRO_FONT_FAMILY_2: 'Montserrat',
      WEBAPPPRO_FONT_FAMILY_3: 'Montserrat',
      WEBAPPPRO_FONT_SIZE_1: '28px',
      WEBAPPPRO_FONT_SIZE_2: '20px',
      WEBAPPPRO_FONT_SIZE_3: '14px',
      WEBAPPPRO_FONT_SIZE_TIPS: '18px',
      WEBAPPPRO_BUTTON_FONT_SIZE: '15px',
      WEBAPPPRO_CARD_BORDER_ROUND: '20px',
      WEBAPPPRO_ICON_COLOR_INFO: '#D6BFF3',
      iconColor: '#313B82',
      cardColor: '#D6BFF3',
      WEBAPPPRO_BUTTON_BORDER_ROUND: '10px',
      WEBAPPPRO_QRCODE_BORDER_COLOR: '20px',
      WEBAPPPRO_QRCODE_SHADOW: 'none',
      WEBAPPPRO_BUTTON_SHADOW_1: 'none',
      WEBAPPPRO_BUTTON_SHADOW_2: 'none',
      WEBAPPPRO_CARD_SHADOW_1: 'none',
      WEBAPPPRO_CARD_SHADOW_2: 'none',
      WEBAPPPRO_CARD_SHADOW_3: 'none',
      WEBAPPPRO_ICON_COLOR_INFO_2: '#ffffff',
      WEBAPPPRO_ICON_COLOR_WARNING_2: '#ffffff',
      WEBAPPPRO_COLOR_FOR_TEXT_BO: '#ffffff',
      WEBAPPPRO_COLOR_FOR_ICON_BO: '#ffffff',
      WEBAPPPRO_CARD_COLOR: '#ffffff',
      WEBAPPPRO_CARD_HOME_BORDER_ROUND: '0px 25px 25px 0px',
      WEBAPPPRO_COLOR_CARD_HOME: '#ffffff',
      WEBAPPPRO_DRAWER_COLOR: '#000000',
      WEBAPPPRO_CARD_BO_COLOR: '#000000',
      WEBAPPPRO_COLOR_1_FILL_ICON: '#000000',
      WEBAPPPRO_COLOR_2_FILL_ICON: '#000000',
      WEBAPPPRO_TEXT_COLOR_3: '#000000',
      WEBAPPPRO_TEXT_COLOR_4: '#000000',
      WEBAPPPRO_COLOR_SKIRT: '#000000',
      WEBAPPPRO_COLOR_HAIR: '#000000',
      WEBAPPPRO_COLOR_GIRL_TOP: '#000000',
      WEBAPPPRO_TEXT_COLOR_ETAPES: '#000000',
      WEBAPPPRO_INPUT_TEXT_COLOR: '#000000',
      WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT: '000000',
      WEBAPPPRO_ALLOW_WALLET: 1,
    },
  }
  hostname = !Object.prototype.hasOwnProperty.call(styles, hostname)
    ? 'default'
    : hostname
  if (!Object.prototype.hasOwnProperty.call(styles[hostname], key)) {
    return ''
  }
  return styles[hostname][key]
}

export const localStorageService = {
  getStyle,
  setObject,
  getValue,
  clearAll,
}
