var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column align-center overflow-y-auto mt-20"},[_c('div',{staticClass:"content-container align-center"},[_c('photo-shooter',{ref:"shooter",on:{"photoReady":function($event){_vm.photoReady = $event}}}),_c('div',{staticClass:"d-flex justify-center"},[(_vm.photoReady)?_c('v-btn',{staticClass:"btn",style:({
            color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
            borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            marginBottom: '40px',
            padding: '25px 100px',
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            width: '327px',
            height: '52px',
          }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1,"disabled":_vm.uploading,"loading":_vm.uploading},on:{"click":_vm.saveAndContinue}},[_vm._v("Je valide ma photo ")]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }