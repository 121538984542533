import axios from 'axios'
import store from '@/store/index'
//import router from '../router/index.js'

const baseURL = process.env.VUE_APP_API_URL

function getUserToken() {
  return store.state.account.user && store.state.account.user.token
  // const user = JSON.parse(localStorage.getItem('user'))
  // return user !== null ? user.token : null
}

function getAuthHeader() {
  // return authorization header with jwt token
  const token = getUserToken()
  const apiKey = localStorage.getItem('token')

  if (token) {
    return {
      language: 'fr',
      shortlive_api_key: localStorage.getItem('shortlive_api_key'),
    }
  } else if (apiKey) {
    return { language: 'fr', Authorization: 'Bearer ' + JSON.parse(apiKey) }
  } else {
    return { language: 'fr' }
  }
}

function ajax(
  { resolve, reject },
  url,
  method = 'GET',
  queryData = null,
  events = {},
  maxTries = 3,
  options = {},
  headers = null,
  body = null,
  responseType = null,
) {
  if (!url || maxTries < 1) {
    return
  }
  const defaultEvents = {
    onProgress: null,
    onUploaded: null,
    always: null,
  }
  Object.assign(defaultEvents, events)

  let requestParameters = null
  if (responseType === null) {
    requestParameters =
      queryData != null
        ? {
            method: method.toLowerCase(),
            baseURL: baseURL,
            url: url,
            data: queryData,
            headers: headers != null ? headers : getAuthHeader(),
            timeout: 3 * 60 * 1000,
            onUploadProgress: events.onProgress,
          }
        : {
            method: method.toLowerCase(),
            baseURL: baseURL,
            url: url,
            body: body,
            headers: headers != null ? headers : getAuthHeader(),
            timeout: 3 * 60 * 1000,
            onUploadProgress: events.onProgress,
          }
  } else {
    requestParameters =
      queryData != null
        ? {
            method: method.toLowerCase(),
            baseURL: baseURL,
            url: url,
            data: queryData,
            headers: headers != null ? headers : getAuthHeader(),
            timeout: 3 * 60 * 1000,
            onUploadProgress: events.onProgress,
            responseType: responseType,
          }
        : {
            method: method.toLowerCase(),
            baseURL: baseURL,
            url: url,
            body: body,
            headers: headers != null ? headers : getAuthHeader(),
            timeout: 3 * 60 * 1000,
            onUploadProgress: events.onProgress,
            responseType: responseType,
          }
  }

  Object.assign(requestParameters, options)
  const request = axios.request(requestParameters)
  request
    .then(response => resolve(response))
    .catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear()
          store.dispatch('alert/error', 'Connexion expirée')
          store.dispatch('account/logout')
          // router.push({name: 'Authentication', params: {
          //   partner_uid: router.currentRoute.params?.partner_uid,
          // },})
        } else if (error.response.status === 403) {
          store.dispatch('alert/error', 'Accès refusé')
          reject({ error: error.response.data })
        } else if (error.response.status === 404) {
          reject({ error: error.response.data, status: 404 })
        } else if (error.response.status === 422) {
          reject({ error: error.response.data, validationFailed: true })
        } else {
          if (error.response.data.error) {
            if (error.response.data.error.message) {
              store.dispatch('alert/error', error.response.data.error.message)
            } else {
              store.dispatch('alert/error', error.response.data.error)
            }
          } else if (error.response.data.message) {
            store.dispatch('alert/error', error.response.data.message)
          } else if (error.response.data.detail) {
            store.dispatch('alert/error', error.response.data.detail)
          } else if (error.response.data.title) {
            store.dispatch('alert/error', error.response.data.title)
          } else {
            store.dispatch('alert/error', error.response.data)
          }
          reject({ error: error.response.data, validationFailed: false })
        }
      } else {
        reject()
      }
    })
    .then(events.always)
}

function get(
  url,
  options = {},
  events = {},
  headers = null,
  responseType = null,
) {
  return new Promise((resolve, reject) => {
    events = events === null ? {} : events
    options = options === null ? {} : events
    ajax(
      { resolve, reject },
      url,
      'get',
      null,
      events,
      3,
      options,
      headers,
      null,
      responseType,
    )
  })
}

function post(
  url,
  data,
  events = {},
  headers = null,
  body = null,
  responseType = null,
) {
  return new Promise((resolve, reject) => {
    events = events === null ? {} : events
    ajax(
      { resolve, reject },
      url,
      'post',
      data,
      events,
      3,
      {},
      headers,
      body,
      responseType,
    )
  })
}

function del(url, events = {}) {
  return new Promise((resolve, reject) => {
    ajax({ resolve, reject }, url, 'delete', null, events)
  })
}

function getImage(imageUid) {
  return `${baseURL}/file/${imageUid}?bearer=${getUserToken()}`
}

function getImageDownloadLink(imageUid) {
  return `${baseURL}/file/${imageUid}/download?bearer=${getUserToken()}`
}

function getImageDownloadAnonymouslyLink(imageUid) {
  return `${baseURL}/file/download-anonymously/${imageUid}`
}
function getImageDownloadAnonymouslyLinkV2(imageUid, qrcodeUid, mainQrCodeUid) {
  return `${baseURL}/file/download-anonymously/${imageUid}/${qrcodeUid}/${mainQrCodeUid}`
}
export const requestService = {
  get,
  post,
  delete: del,
  getImage,
  getImageDownloadLink,
  getImageDownloadAnonymouslyLink,
  getImageDownloadAnonymouslyLinkV2,
}
